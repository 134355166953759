import "@fontsource/noto-sans-jp"
import "./src/styles/base.css"
import "./src/styles/blog-contents-style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./src/styles/base.css";

export const onRouteUpdate = ({ location }) => {
  if (typeof document !== "undefined") {
    if (location.pathname === "/") {
      document.body.classList.add("special")
    } else if (location.pathname.includes("/recruit/")) {
      document.body.classList.add("special")
      document.body.classList.add("special01")
    }
  }
  // 画面スクロール時に要素をフェードインさせる
  const handleScroll = () => {
    const elements = document.querySelectorAll('.fadeInUp');
    elements.forEach(element => {
      const rect = element.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      if (rect.top <= windowHeight) {
        element.classList.add('on');
      }
    });
  };

  window.addEventListener('scroll', handleScroll);

  // 初回ロード時にも実行して要素をチェック
  handleScroll();

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
  
}
